import React from "react";

function ProductFeatures() {
    return (
        <div>
            <h4>Features</h4>
            <ul>
                <li>Cras ornare tristique elit.</li>
                <li>Vivamus vestibulum ntulla nec ante.</li>
                <li>Praesent placerat risus quis eros.</li>
            </ul>
        </div>
    );
}

export default ProductFeatures;