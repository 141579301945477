import React from "react";

function ProductDescription() {
    return (
        <div>
            <h4>Product Description</h4>
            <ul className="product-description">
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing.</li>
                <li>Aliquam tincidunt mauris eu risus.</li>
                <li>Vestibulum auctor dapibus neque.</li>
                <li>Nunc dignissim risus id metus.</li>
            </ul>
        </div>
    );
}

export default ProductDescription;